import React from "react"
import {
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Select,
  Input,
  Label,
} from "@theme-ui/components"

export default () => (
  <Flex sx={{ bg: "black", px: [7, 14], py: [5, 10] }}>
    <Box sx={{ color: "white" }}>
      <Heading sx={{ fontSize: 24, mb: [3, 1] }}>Get in touch with us</Heading>

      <Text sx={{ fontSize: 14, mb: 6 }}>
        We love talking to people and are happy to hear about your next project.
        We're also happy to just shoot the shit. Let's chat.
      </Text>

      <Box sx={{ width: "37px", height: "3px", bg: "white", mb: 6 }} />

      <form
        action="/success"
        name="Contact"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="Contact" />
        <Flex sx={{ flexDirection: "column" }}>
          <Box>
            <Label>Name</Label>
            <Input name="name" />
          </Box>

          <Box>
            <Label>Email</Label>
            <Input name="email" />
          </Box>

          <Box sx={{ position: "relative", mb: 2 }}>
            <Label>Type of project</Label>
            <Select name="project[]">
              <option value="">Select one</option>
              <option value="Landing Page">Landing page</option>
              <option value="Web App">Web app</option>
              <option value="Telepathy">Telepathy</option>
              <option value="Mobile app">Mobile app</option>
              <option value="Cybernetics">Cybernetics</option>
              <option value="Inter-dimensional Travel">
                Inter-dimensional Travel
              </option>
              <option value="Branding">Branding</option>
              <option value="Other">Other</option>
            </Select>
          </Box>
        </Flex>

        <Button type="submit" variant="small" sx={{ mb: 6 }}>
          Send
        </Button>
      </form>

      <Text sx={{ fontSize: 14 }}>
        We don't charge for pointless meetings, project management fees, or any
        other bullshit. We're here to build something, not just ring up a big
        receipt.
      </Text>
    </Box>
  </Flex>
)
