import React from "react"
import { Flex, Heading } from "@theme-ui/components"

import Project from "../components/project"
import Container from "../components/container"

import dorsiaBrowser from "../images/dorsia-browser.png"
import iotacssBrowser from "../images/iotacss-browser.png"

export default () => (
  <Flex sx={{ py: 14, flexDirection: "column", color: "white", bg: "#131313" }}>
    <Heading sx={{ pb: 14, fontSize: [28, 32], textAlign: "center" }}>
      Selected Work
    </Heading>

    <Container small>
      <Project
        title="Dorsia"
        type="Client Work"
        link="https://www.dorsia.io"
        image={dorsiaBrowser}
      >
        We only have so much time on this planet. Any time wasted going to the
        wrong place for the wrong reason, or sifting through useless reviews and
        out-of-date travel guides is a terrible, terrible thing.
      </Project>

      <Flex as="hr" sx={{ my: [10, 14] }} />

      <Project
        title="iotaCSS"
        type="Open Source"
        link="https://www.iotacss.com"
        image={iotacssBrowser}
      >
        Smart open source SASS based OOCSS framework built for scale. Create
        lightweight, performant, readable and fully responsive interfaces in no
        time.
      </Project>
    </Container>
  </Flex>
)
