import React from "react"
import { Styled } from "theme-ui"
import { Flex, Box, Image, Grid, Button, Heading } from "@theme-ui/components"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Container from "../components/container"
import Separator from "../components/separator"
import ContactForm from "../components/contact-form"
import Projects from "../views/projects"

import slogan from "../images/slogan.svg"
import headerImage from "../images/computer.jpg"

const IndexPage = () => (
  <Layout>
    <Styled.root>
      <SEO title="You can't see the future unless you create it." />

      <Container>
        <Grid columns={[1, 2]} mb={12}>
          <Box>
            <Image src={slogan} sx={{ mb: 5 }} />
            <Box sx={{ mb: 7, maxWidth: "460px" }}>
              <Styled.p>
                We’re here to help you{" "}
                <strong>
                  take your idea from the back of the napkin to the world wide
                  web
                </strong>
                . You know your customers best, so leave the dirty work to us.
              </Styled.p>
            </Box>
            <a href="mailto:info@humidresearch.com">
              <Button>Get in touch</Button>
            </a>
          </Box>
          <Box sx={{ display: ["none", "block"] }}>
            <Image src={headerImage} />
          </Box>
        </Grid>

        <Separator sx={{ mb: 12 }} />

        <Flex sx={{ maxWidth: "container", mx: "auto" }}>
          <Grid columns={[1, 2]} gap={[5, 20]} sx={{ mb: 12 }}>
            <Box sx={{ flexBasis: "50%" }}>
              <Heading sx={{ mb: 4 }}>
                Do you have the next game-changing idea that you want to test in
                the market?
              </Heading>
              <Styled.p>
                Are you looking for an MVP that you can show investors? Do you
                need a data aggregation pipeline that you can use to test your
                hypothesis? <br />
                <br />
                Humid Research was created to help companies get to market, and
                test their ideas faster than the competition.
                <br />
                <br /> We have years of experience building large scale systems
                that have been battle-tested in the real world. Our background
                in rapid-prototyping and design system development have given us
                the tools that are necessary to develop your applications.
              </Styled.p>
            </Box>
            <Box sx={{ flexBasis: "50%" }}>
              <Styled.p>
                Our engineering teams are trained in the latest cutting edge
                technologies and runtimes. <br />
                <br />
                <Styled.blockquote>
                  We’re a one-stop-shop
                  <br />
                  development studio.
                </Styled.blockquote>
                <br />
                We can handle designing complex algorithms, radical graphics,
                and even host your project on the World Wide Web! Imagine what
                your customers will think when they see your product while
                surfing the internet super-highway, or how impressed your
                executive team will be when they see the data you collected
                guiding the stock price up into the triple digits.
                <br />
                <br />
                <Styled.strong>We’re here for you.</Styled.strong>
              </Styled.p>
            </Box>
          </Grid>
        </Flex>

        <Separator sx={{ mb: 12 }} />

        <Flex sx={{ maxWidth: "container", mx: "auto" }}>
          <Grid
            columns={[1, 2]}
            gap={[5, 20]}
            sx={{ mb: 12, alignItems: "center" }}
          >
            <Box sx={{ flexBasis: "50%" }}>
              <Heading sx={{ fontSize: [null, 32], mb: 6 }}>
                Are you ready to get
                <br />
                down to business?
              </Heading>
              <Styled.ul>
                <Styled.li>
                  We’re experts in data aggregation, interface design, and
                  building systems that SCALE.
                </Styled.li>
                <Styled.li>
                  Our React.js, Node.js, and iOS engineers, are here to make
                  your next project a reality.
                </Styled.li>
                <Styled.li>
                  Our team is here to take your concept and turn it into an MVP.
                  Are you ready to test your idea in the real world?
                </Styled.li>
              </Styled.ul>
            </Box>
            <Box sx={{ flexBasis: "50%" }}>
              <ContactForm />
            </Box>
          </Grid>
        </Flex>
      </Container>

      <Projects />
    </Styled.root>
  </Layout>
)

export default IndexPage
