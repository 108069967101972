import React from "react"
import { Box, Heading, Text, Link, Button, Image } from "@theme-ui/components"
import { Flex as GridRow, Box as GridCol } from "reflexbox"

export default ({
  title = "",
  type = "",
  link = "",
  image = "",
  sx = {},
  children,
  ...props
}) => (
  <Box sx={{ overflow: "hidden", ...sx }} {...props}>
    <GridRow flexWrap="wrap" alignItems="center" mx={-7}>
      <GridCol width={[1, 1 / 2, 1 / 3]} px={7}>
        <Heading sx={{ mb: 4 }}>
          {title}{" "}
          <Text
            sx={{
              display: "inline",
              ml: 2,
              opacity: 0.7,
              fontFamily: "body",
              fontSize: 14,
            }}
          >
            {type}
          </Text>
        </Heading>
        <Text sx={{ fontSize: 16, mb: 8 }}>{children}</Text>
        <Link
          href={link}
          target="_blank"
          sx={{ display: ["none", "flex"], textDecoration: "none" }}
        >
          <Button variant="small">Visit</Button>
        </Link>
      </GridCol>
      <GridCol width={[1, , 1 / 2, 2 / 3]} px={7}>
        <Image src={image} mb={8} />
        <Link
          href={link}
          target="_blank"
          sx={{ display: ["flex", "none"], textDecoration: "none" }}
        >
          <Button variant="small">Visit</Button>
        </Link>
      </GridCol>
    </GridRow>
  </Box>
)
